<!-- =========================================================================================
  File Name: CarouselEffectFade.vue
  Description: Carousel with fade effect
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card :title="currentVenue.arabic_name">
        <!-- swiper -->
        <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
            <swiper-slide v-for="image in images" :key="image.id">                
                               
                <img class="responsive h-auto max-h-400"  :src="baseURL+'/'+image.image" alt="banner">
            </swiper-slide>
            
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
      </vx-card>
</template>





<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  data () {
    return {
      swiperOption: {
        spaceBetween: 30,
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
      baseURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
      
      currentVenue () {
        return JSON.parse(this.$store.getters.currentVenue)
    },
  },
  components: {
    swiper,
    swiperSlide
  },
  props:{
      images: Array
  }
}
</script>
<style lang="scss">
.max-h-400{
  max-height: 400px;
}
</style>