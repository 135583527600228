<template>
<div>

    <vx-card :title="$t('onBoarding.services')">
        <div class="container-fluid mt-2 px-4">
            <div class="vx-row justify-end">
                <vs-button icon="add" @click="addService">{{ $t("Add")}}</vs-button>
            </div>
        </div>
        <div class="container-fluid mt-2 px-0 mb-5">
            <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-3" v-if="services && services.length > 0">
                <div class="flex-none w-20" v-for="service in services" :key="service.id">
                    <vs-card class="cardx my-5" fixed-height>
                        <div slot="media">
                            <img :src="imagesURL+'/'+service.main_image" class="object-cover h-48">
                        </div>
                        <div slot="extra-content">

                            <!-- EDIT & ACTION BUTTONS -->
                            <div class="container">
                                <div class="vx-row justify-end px-2 pt-2">
                                    <div class="flex">
                                        <vs-dropdown class="mx-1">
                                          <vs-button color="dark" type="border" icon="more_horiz"></vs-button>
                                          <vs-dropdown-menu>
                                            <vs-dropdown-item>
                                                <vs-button class="mt-2 px-2" @click="duplicateService(service)" icon-pack="FA5" icon="far fa-copy" type="flat" color="dark">{{$t('home.serviceForm.duplicateService')}}</vs-button>
                                            </vs-dropdown-item>
                                            <vs-dropdown-item>
                                                <vs-button class="mt-2 px-2" @click="deleteBookingOptionActive = true; currentService = service" icon-pack="FA5" icon="far fa-trash" type="flat" color="danger">{{$t('home.serviceForm.deleteService')}}</vs-button>
                                            </vs-dropdown-item>
                                          </vs-dropdown-menu>
                                          
                                      </vs-dropdown>
                                      <vs-button color="primary" type="border" icon="edit" @click="editService(service)"></vs-button>
                                    </div>
                                </div>

                                <hr>
                            </div>
                            <!--// EDIT & ACTION BUTTONS //-->



                            <!-- BOOKING OPTION DATA (NAME - DESCRIPTION.. ETC) -->
                            <div class="">
                                <div class="vx-row px-2 py-5">
                                    <div class="vx-col w-2/3">
                                        <span>{{service.name}}</span> <br /> <small class="font-medium">{{service.description}}</small>
                                    </div>
                                    <div class="vx-col w-1/3">
                                        <small class="font-medium">{{$t('home.editBookingOptionForm.thePrice')}}</small> 
                                        <br />
                                        <span v-if="service.pricing_type == 'fixed'">{{service.final_price.toLocaleString()}} {{$t('SAR')}}</span>
                                        <span v-else>
                                            <span v-if="service.first_input_price > 0">{{Math.round((service.first_input_price * 1.15)).toLocaleString()}} {{$t('SAR')}} <small class="font-medium">/ {{$t('person')}}</small></span>
                                            <br v-if="service.second_input_price > 0" />
                                            <span v-if="service.second_input_price > 0">{{Math.round((service.second_input_price * 1.15)).toLocaleString()}} {{$t('SAR')}} <small class="font-medium">/ {{$t('hour')}}</small></span>
                                        </span>
                                        <br /> 
                                        <small class="font-medium">({{$t('vatIncluded')}})</small> 
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>


                    </vs-card>
                </div>
            </div>
            <div class="row w-full mt-10 lg:mt-5 flex flex-wrap justify-center" v-else-if="services && services.length == 0">
              <h5 class="mt-3 mr-2 text-center gray-note">{{$t('home.servicesTable.noServicesAvailable')}}</h5>
            </div>
        </div>
    </vx-card>

    <!-- Edit booking option popup -->
    <add-on-form :serviceActive.sync="serviceActive" :currentService="currentService" @updatecurrentService="currentService = $event" :isEdit="isEdit" :actionType="actionType"/>

    <vs-prompt :title="$t('home.serviceForm.delete.promptHeader')" color="danger" @accept="deleteBookingOption" @cancel="currentService = null" @close="currentService = null" :accept-text="$t('home.editBookingOptionForm.delete.accept')" :cancel-text="$t('home.editBookingOptionForm.delete.cancel')" :active.sync="deleteBookingOptionActive" v-if="currentService">
      {{$t('home.serviceForm.delete.message', { serviceName: currentService.name })}}
    </vs-prompt>
</div>
</template>

<script>
import axios from '@/axios'
import AddOnForm   from '/src/components/AddOnForm.vue'
export default {
data () {
    return {      
      services: [],

      serviceActive: false,
      editBookingOptionPromptActive: false,
      deleteBookingOptionActive: false,
      currentService : {},
      isEditChangeRequest: false,
      isEdit: false,
      isLinkedWithBookingOption: false,
      actionType: null,
    }
  },
  components: {
      AddOnForm
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    isOnBoarding() {
        let pathArray = window.location.href.split('/');
        return pathArray[pathArray.length - 1] == 'on-boarding';
    },
    imagesURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
  },
  created () {
      this.getservicesAPI();
      this.$store.dispatch('loader/loaderOff');
  },
  methods: {
    getservicesAPI() {
        this.$store.dispatch('loader/loaderOn');
        axios.get(`/venues/${this.currentVenueId}/services`)
            .then((response) => {    
                this.services = response.data.services
            })
            .catch((error)   => { console.log(error) })
            .finally(() => {
                this.$store.dispatch('loader/loaderOff')
            })
    },
    editService(service) {
        this.actionType = 'editService';
        this.isEdit = true;
        this.currentService = service;
        this.serviceActive = true;
    },
    addService() {
        this.currentService = {
            pricing_type: 'fixed',
            percentage_tax: 1,
            custom_prices: [],
            images: []
        };
        this.actionType = 'addService';
        this.serviceActive = true;
        this.isEdit = false;
    },
    duplicateService(service) {
        this.actionType = 'duplicateService';
        this.isEdit = true;
        this.currentService = service;
        this.serviceActive = true;
    },
    deleteBookingOption() {
        let source = this.isOnBoarding ? 'OnBoarding' : 'Normal';
      
        axios.delete(`/venues/${this.currentVenueId}/services/${this.currentService.id}`, 
        {
            data: {
                source: source,
            }
        })
        .then((response) => {
            this.$vs.notify({
                color:'success',
                title: this.$t('editsDone'),
                text: response.data.success_message || 'تم حذف الخدمة بنجاح'
            })
            this.getservicesAPI();
        })
        .catch((error) => { 
            this.$vs.notify({
                color:'danger',
                title: this.$t('error'),
                text: error ||'عذرًا لم نستطع حذف خيار الحجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
            })
            })
    }
  }
}
</script>

<style>

</style>