<!-- TODO: Delete this file after removing it from the on boarding -->
<template>
<div>

    <vx-card :title="$t('home.bookingOptions')">
        <div class="container-fluid mt-2 px-4">
            <div class="vx-row justify-end">
                <vs-button icon="add" @click="addBookingOption">{{ $t("Add")}}sssssss</vs-button>
            </div>
        </div>
        <div class="container-fluid mt-2 px-0 mb-5">
            <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-3" v-if="bookingOptions && bookingOptions.length > 0">
                <div class="flex-none w-20" v-for="bookingOption in bookingOptions" :key="bookingOption.id">
                    <vs-card class="cardx my-5" fixed-height>
                        <div slot="media">
                            <!-- <img :src="imagesURL+'/'+bookingOption.main_image" class="object-cover h-48"> -->
                            <img :src="imagesURL+'/'+ (bookingOption.images.length > 0 ? bookingOption.images[0] : bookingOption.main_image)" class="object-cover h-48">
                        </div>
                        <div slot="extra-content">
<!--  -->
                            <!-- EDIT & ACTION BUTTONS -->
                            <div class="container">
                                <div class="vx-row justify-between px-2 pt-2">
                                    <div>
                                      <vs-tooltip class="mt-2" text="This booking option is available to the customers" v-if="bookingOption.availability == 'available'">
                                          <vs-chip color="success">
                                              {{$t('home.editBookingOptionForm.bookingOptionAvailable')}}
                                          </vs-chip>
                                      </vs-tooltip>
                                      <vs-tooltip v-else text="his booking option is hidden from the customers">
                                          <vs-chip color="warning">
                                              {{$t('home.editBookingOptionForm.bookingOptionHidden')}}
                                          </vs-chip>
                                      </vs-tooltip>

                                    </div>
                                    <div class="flex">
                                      <vs-dropdown class="mx-1">
                                          <vs-button color="dark" type="border" icon="more_horiz"></vs-button>
                                          <vs-dropdown-menu>
                                              <vs-dropdown-item v-if="bookingOption.has_seasonal_pricing">
                                                  <vs-button class="px-2" type="flat" color="dark" icon-pack="FA5" icon="fas fa-dollar-sign" :to="{ name: 'custom-pricings', params: { option_id: bookingOption.id }, hash: '#seasonalPricings'}">{{$t('home.seasonalPricings')}}</vs-button>
                                              </vs-dropdown-item>
                                              <vs-dropdown-item v-if="bookingOption.has_days_pricing">
                                                  <vs-button class="px-2" type="flat" color="dark" icon-pack="FA5" icon="fas fa-dollar-sign" :to="{ name: 'custom-pricings', params: { option_id: bookingOption.id }, hash: '#daysPricings'}">{{$t('home.daysPricings')}}</vs-button>
                                              </vs-dropdown-item>
                                              <vs-dropdown-item>
                                                  <vs-button class="mt-2 px-2" @click="duplicateBookingOption(bookingOption)" icon-pack="FA5" icon="far fa-copy" type="flat" color="dark">{{$t('home.editBookingOptionForm.duplicateBookingOption')}}</vs-button>
                                              </vs-dropdown-item>
                                              <vs-dropdown-item>
                                                  <vs-button class="mt-2 px-2" @click="deleteBookingOptionActive = true; bookingOptionToDelete = bookingOption" icon-pack="FA5" icon="far fa-trash" type="flat" color="danger">{{$t('home.editBookingOptionForm.deleteBookingOption')}}</vs-button>
                                              </vs-dropdown-item>
                                          </vs-dropdown-menu>
                                          
                                      </vs-dropdown>
                                      <vs-button color="primary" type="border" icon="edit" @click="editBookingOption(bookingOption)"></vs-button>
                                    </div>
                                </div>

                                <hr>
                            </div>
                            <!--// EDIT & ACTION BUTTONS //-->



                            <!-- BOOKING OPTION DATA (NAME - DESCRIPTION.. ETC) -->
                            <div class="">
                                <div class="vx-row px-2 py-5">
                                    <div class="vx-col w-2/3">
                                        <span>{{bookingOption.name}}</span> <br /> <small class="font-medium">{{bookingOption.description}}</small>
                                    </div>
                                    <div class="vx-col w-1/3">
                                      <vs-tooltip :text="$t('home.editBookingOptionForm.taxApplied')">
                                        <small class="font-medium">{{$t('home.editBookingOptionForm.thePrice')}}</small> <br /> <span>{{bookingOption.is_taxable_final ? Math.round((bookingOption.price * 1.15)).toLocaleString() : bookingOption.price.toLocaleString()}} {{$t('SAR')}}</span>
                                      </vs-tooltip>
                                    </div>
                                </div>
                                
                            </div>
                            <!-- BOOKING OPTION DATA (NAME - DESCRIPTION.. ETC) -->
                            <div class="container mt-2 text-center flex-1 justify-center items-center" v-if="bookingOption.event_start_date_str || bookingOption.bookings_count_per_size_str">
                              <hr>
                              <div class="px-2 mt-5">
                                    <div class="" style="background: #b3b3b3; border-radius: 20px; color: white; text-align: center">
                                        <span>{{bookingOption.bookings_count_per_size_str}}</span>
                                    </div>
                              </div>
                              <div class="px-2 mt-5" v-if="bookingOption.event_start_date_str">
                                    <div class="" style="border-radius: 20px; text-align: center">
                                        <span>{{bookingOption.event_start_date_str}}</span>
                                    </div>
                              </div>
                            </div>
                            <!-- <div class="container mt-2 text-center flex-1 justify-center items-center">
                              <div class="px-2 py-5">
                                    <div class="" style="background: #b3b3b3; border-radius: 20px; color: white; text-align: center">
                                        <span>{{bookingOption.bookings_count_per_size_str}}</span>
                                    </div>
                              </div>
                            </div>

                            <div v-if="bookingOption.event_start_date_str" class="container mt-2 text-center flex-1 justify-center items-center">
                              <div class="px-2 py-5;">
                                    <div class="" style="border-radius: 20px; text-align: center">
                                        <span>{{bookingOption.event_start_date_str}}</span>
                                    </div>
                              </div>
                            </div> -->
                        </div>


                    </vs-card>
                </div>
            </div>
            <div class="row w-full mt-10 lg:mt-5 flex flex-wrap justify-center" v-else-if="bookingOptions && bookingOptions.length == 0">
              <h5 class="mt-3 mr-2 text-center gray-note">{{$t('bookingOptionPreview.noBookingOptions')}}</h5>
            </div>
        </div>
    </vx-card>
    <vs-popup class="holamundo "  :title="currentBookingOption.name" :active.sync="currentBookingOptionIsActive">
        <div class="container">

          <div class="flex flex-wrap">
            <vs-row class="w-full">
              <image-slider :images="currentBookingOption.images"/>
            </vs-row>

              <vs-row class="w-full">
                <vs-input readonly :label="$t('bookingOptionPreview.description')"
                  v-model="currentBookingOption.description" class="mt-5 w-full pr-2" name="bookingOptionDescription" />

                <vs-textarea class="mt-5" readonly :label="$t('bookingOptionPreview.description')" v-model="currentBookingOption.detailedDescription" />
              </vs-row>

              <div class="vx-col w-full md:mb-2 mt-2 ">
                <div class="m-0 flex flex-col text-left items-start">
                  <div class="flex flex-col">
                    <vs-checkbox disabled v-model="currentBookingOption.later_deposit_payment_enabled" name="is_separated" class="mt-2">{{$t('bookingOptionPreview.laterDepositAllowed')}}</vs-checkbox>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </vs-popup>

    <!-- BOOKING OPTION POPUP FORM -->
    <booking-option-form :editBookingOptionActive.sync="editBookingOptionActive" :currentBookingOption="currentBookingOption" @updateCurrentBookingOption="currentBookingOption = $event" :isEditBookingOption="isEditBookingOption" :actionType="actionType" />

    <vs-prompt title="" class="export-options" @accept="editBookingOptionActive = true" :accept-text="$t('home.editBookingOptionPrompt.accept')" :cancel-text="$t('home.editBookingOptionPrompt.cancel')" :active.sync="editBookingOptionPromptActive">
        {{$t('home.editBookingOptionPrompt.message')}}
    </vs-prompt>

    <vs-prompt :title="$t('home.editBookingOptionForm.delete.promptHeader')" color="danger" @accept="deleteBookingOption" @cancel="bookingOptionToDelete = null" @close="bookingOptionToDelete = null" :accept-text="$t('home.editBookingOptionForm.delete.accept')" :cancel-text="$t('home.editBookingOptionForm.delete.cancel')" :active.sync="deleteBookingOptionActive" v-if="bookingOptionToDelete">
      {{$t('home.editBookingOptionForm.delete.message', { bookingOptionName: bookingOptionToDelete.name })}}
    </vs-prompt>
</div>
</template>

<script>
import axios from '@/axios'
import ImageSlider   from '/src/views/q-pages/ImagesSlider.vue' // carousel
export default {
data () {
    return {
      bookingOptions: null,
      newBookingOptions: [],

      currentBookingOptionIsActive: false,
      editBookingOptionActive: false,
      deleteBookingOptionActive: false,
      bookingOptionToDelete: null,
      editBookingOptionPromptActive: false,
      currentBookingOption : {},
      isEditBookingOption: false,
      actionType: null,
    }
  },
  components: {
      ImageSlider
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    isOnBoarding() {
        let pathArray = window.location.href.split('/');
        return pathArray[pathArray.length - 1] == 'on-boarding';
    },
    imagesURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
  },
  created () {
    this.$store.dispatch('loader/loaderOn')
    this.getBookingOptionsAPI();
    this.getNewBookingOptionsAPI();
  },
  methods: {
    getBookingOptionsAPI() {
      axios.get(`/venues/${this.currentVenueId}/bookingOptions`)
        .then((response) => {
          this.bookingOptions = response.data.data
        })
        .catch((error)   => { console.log(error) })
        .finally(() => {
          this.$store.dispatch('loader/loaderOff')
        })
    },
    getNewBookingOptionsAPI() {
      axios.get(`/venues/${this.currentVenueId}/bookingOptions/newBookingOptions`)
        .then((response) => {
          this.newBookingOptions = response.data.data
        })
        .catch((error)   => { console.log(error) })
        .finally(() => {
          this.$store.dispatch('loader/loaderOff')
        })
    },

    showBookingOption(bookingOption){
        console.log('rere')
        this.currentBookingOption = bookingOption;
        this.currentBookingOptionIsActive = true;
    },
    editBookingOption(bookingOption) {
        this.actionType = 'editBookingOption';
        this.isEditBookingOption = true;
        this.currentBookingOption = bookingOption;
        this.editBookingOptionActive = true;
    },
    duplicateBookingOption(bookingOption) {
        this.actionType = 'duplicateBookingOption';
        this.isEditBookingOption = true;
        this.currentBookingOption = bookingOption;
        this.editBookingOptionActive = true;
    },
    addBookingOption() {
        this.currentBookingOption = {
            pricing_type : 'fixed',
            is_taxable : 1,
            price : 0,
            man_price : 0,
            woman_price : 0,
            availability: 'available',
            range_pricing: [],
            has_seasonal_pricing: 0,
            has_days_pricing: 0
        };
        this.editBookingOptionActive = true;
        this.isEditBookingOption = false;
        this.actionType = 'addBookingOption';
    },
    deleteBookingOption() {
      let source = this.isOnBoarding ? 'OnBoarding' : 'Normal';
      
      axios.delete(`/venues/${this.currentVenueId}/bookingOptions/${this.bookingOptionToDelete.id}`, 
      {
        data: {
          source: source,
        }
      })
      .then((response) => {
        this.$vs.notify({
            color:'success',
            title: this.$t('editsDone'),
            text: response.data.success_message || 'تم حذف باقة الحجز بنجاح'
        })
        this.getBookingOptionsAPI();
      })
      .catch((error) => { 
        this.$vs.notify({
            color:'danger',
            title: this.$t('error'),
            text: error ||'عذرًا لم نستطع حذف خيار الحجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
        })
        })
      // console.log(this.bookingOptionToDelete);
    }
  }
}
</script>

<style>
.vs-tooltip {
  z-index: 99999 !important;
}

hr{
  height: 0.5px;
  border-color: rgb(255, 255, 255) !important;
  opacity: 0.5;
  margin-top: 10px;
}
</style>
