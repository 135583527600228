<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="scaffold">
    <div class="vx-row">
      <div class="vx-col w-full mb-10 ">
        <vs-tabs>
          <vs-tab :label="$t('home.bookingOptions')">
            <!-- <booking-options-table /> -->
              <booking-options-list />
          </vs-tab>
          <vs-tab :label="$t('home.services')">
              <!-- <add-ons-table /> -->
              <services-list />
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </div>
</template>

<script>

import BookingOptionsTable   from '/src/views/components/qosoor-components/BookingOptionsTable.vue'
import BookingOptionsList from '/src/views/components/BookingOptions/BookingOptionsList.vue'
import AddOnsTable from '@/views/components/qosoor-components/AddOnsTable.vue'
import ServicesList from '@/views/components/Services/ServicesList.vue'
export default {
  data () {
    return {}
  },
  components: {
    BookingOptionsTable,
    BookingOptionsList,
    AddOnsTable,
    ServicesList
  },
  computed: {},

  created () {},
  methods: {},
  watch: {}
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#scaffold {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}

</style>
